<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Work information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Principal Employee">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.principalEmployeeId"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="card info-card">
      <el-form ref="work-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Work Name">
            <el-input v-model="form.workName"></el-input>
          </el-form-item>
          <el-form-item label="Status">
            <el-select v-model="form.status" placeholder="Please select status">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Deadline">
            <el-date-picker
              v-model="form.deadline"
              type="datetimerange"
              range-separator="|"
              start-placeholder="Start Date"
              end-placeholder="End Date">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Priority" class="priority-field">
            <el-select v-model="form.priority" placeholder="Please select priority">
              <el-option
                v-for="item in priorityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Customer Requirment" style="flex-basis: 59%;">
            <el-input
            type="textarea"
            :rows="3"
            v-model="form.customerRequirment" />
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Third Party Related" style="flex-basis: 59%;">
            <el-input
            type="textarea"
            :rows="3"
            v-model="form.thirdParty" />
          </el-form-item>
        </div>
      </el-form>

      <div class="work-members">
        <h2 class="card-title">Work Members</h2>
        <WorkMembers @updateWorkMembers="updateWorkMembers" />
      </div>

      <div class="related-customer">
        <h2 class="card-title">Related Customers</h2>
        <RelatedCustomers @updateCustomers="updateCustomers" />
      </div>

      <div class="related-order">
        <h2 class="card-title">Related Orders</h2>
        <RelatedOrders @updateOrders="updateOrders" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import WorkMembers from '@/components/work/WorkMembers.vue';
import RelatedCustomers from '@/components/work/RelatedCustomers.vue';
import RelatedOrders from '@/components/work/RelatedOrders.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';


export default {
  name: 'WorkForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  components: {
    WorkMembers,
    RelatedCustomers,
    RelatedOrders,
  },
  mounted(){
    if(this.viewMode === true){
      $('.work-members-wrapper .editButton').css('display', 'none');
      $('.customer-members-wrapper .editButton').css('display', 'none');
      $('.related-orders-wrapper .editButton').css('display', 'none');
    }
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      principalEmployeeId: '',
      workName: '',
      status: '',
      deadline: '',
      priority: '',
      customerRequirment: '',
      thirdParty: '',
      workMembers: [],
      relatedCustomers: [],
      relatedOrders:[],
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
      statusOptions:[
        {'label': 'Pending', 'value': 'Pending'},
        {'label': 'Processing', 'value': 'Processing'},
        {'label': 'Canceled', 'value': 'Canceled'},
        {'label': 'Completed', 'value': 'Completed'}
      ],
      priorityOptions: [
        {'label': 'High', 'value': 'High'},
        {'label': 'Medium', 'value': 'Medium'},
        {'label': 'Low', 'value': 'Low'},
      ],
    };
  },
  methods:{
    startEdit(){
      $('.work-members-wrapper .editButton').css('display', 'block');
      $('.customer-members-wrapper .editButton').css('display', 'block');
      $('.related-orders-wrapper .editButton').css('display', 'block');
      this.disabled = false;
    },
    async save(){
      $('.work-members-wrapper .editButton').css('display', 'none');
      $('.customer-members-wrapper .editButton').css('display', 'none');
      $('.related-orders-wrapper .editButton').css('display', 'none');
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
    updateWorkMembers(workMembers){
      this.form.workMembers = workMembers;
    },
    updateCustomers(customers){
      this.form.relatedCustomers = customers;
    },
    updateOrders(orders){
      this.form.relatedOrders = orders;
    },
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }
      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  watch: {
    'currentWork': function(){
      this.form = {
        principalEmployeeId: this.currentWork.employee_name,
        workName: this.currentWork.work_name,
        status: this.currentWork.status,
        deadline: [
          dayjs.unix(this.currentWork.deadline_start),
          dayjs.unix(this.currentWork.deadline_end),
        ],
        priority: this.currentWork.priority,
        customerRequirment: this.currentWork.customer_requirment,
        thirdParty: this.currentWork.third_party,
        workMembers: this.currentWork.work_members,
        relatedCustomers: this.currentWork.related_customers,
        relatedOrders:this.currentWork.related_orders,
      };
    }
  },
  computed: mapState({
    currentWork: state => state.work.currentWork,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.work-members, .related-customer{
  margin-top: 50px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 50px;
}


.priority-field{
  position: relative;
  right: 50px;
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
