<template>
  <div class="related-orders-wrapper">
    <div class="action-button-wrapper">
      <div class="add-order-wrapper">
        <el-autocomplete
        class="inline-input addMemberInput"
        :fetch-suggestions="searchOrder"
        v-model="currentInputOrder"
        placeholder="Input Order Reference ID"
        :trigger-on-focus="false"
        v-if="editMode"
        ></el-autocomplete>
        <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Add</button>
      </div>
      <button v-if="!editMode" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Reference Id</th>
          <th scope="col">Customer Name</th>
          <th v-if="editMode" scope="col">Remove</th>
          <th v-if="!editMode" scope="col">View</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="order in orders">
          <th scope="row">{{order.reference_id}}</th>
          <td>{{order.customer_name}}</td>
          <td v-if="editMode"><a href="#" @click.prevent="removeOrder(order.id)">Remove</a></td>
          <td><router-link :to="{name:'Order', params: {id: order.id}}">View</router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'RelatedOrders',
  data(){
    return {
      editMode: false,
      orders: [],
      currentInputOrder: '',
    };
  },
  methods: {
    startEdit(){
      this.editMode = true;
    },
    removeOrder(orderId){
      const newOrders = this.orders.filter(function(item) {
        return item.id !== orderId;
      });
      this.orders = newOrders;
    },
    async save(){
      const referenceId = this.currentInputOrder;
      const order = await this.getOrderByRid(referenceId);
      if(order){
        this.orders.push(order);
        this.currentInputOrder = '';
      }
      this.$emit('updateOrders', this.orders);
      this.editMode = false;
    },
    async getOrderByRid(referenceId){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'reference_id': referenceId,
      };
      const orders = await Sales.searchOrder(this.apiUrl, searchFields, loginInfo);
      const order = orders[0];
      return order;
    },
    /* Customer Filter */
    createOrderFilter(queryString) {
      return (order) => {
        return (order.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    getTotalAmount(order){
      let totalAmount = 0;
      const orderItems = order.order_items;
      for(let i = 0; i < orderItems.length; i++){
        const orderItem = orderItems[i];
        const itemAmount = orderItem.item_amount;
        const itemNumber = orderItem.item_number;
        const itemTotalAmount = itemAmount * itemNumber;
        totalAmount += itemTotalAmount;
      }
      return totalAmount;
    },
    /* For auto select */
    async searchOrder(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const orders = await Sales.loadAllOrders(this.apiUrl, loginInfo);
      const orderSuggest = [];

      for(let i = 0; i < orders.length; i++){
        const order = orders[i];
        const orderSelection = {
          'value': order.reference_id,
          'name': order.reference_id
        };
        orderSuggest.push(orderSelection);
      }

      const results = queryString ? orderSuggest.filter(this.createOrderFilter(queryString)) : orders;

      cb(results);
    },
  },
  watch: {
    'currentWork': function(){
      if(this.currentWork.related_orders){
        this.orders = this.currentWork.related_orders;
      }
    }
  },
  computed: mapState({
    currentWork: state => state.work.currentWork,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-orders-wrapper{
  .action-button-wrapper{
    margin-bottom: 0;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
  .add-order-wrapper{
    display: flex;
    align-items: center;
    flex: 1;
  }
  .addMemberInput{
    margin-right: 20px;
  }
}
</style>
