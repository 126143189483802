<template>
  <div class="customer-members-wrapper">
    <div class="action-button-wrapper">
      <div class="add-member-wrapper">
        <el-autocomplete
        class="inline-input addMemberInput"
        :fetch-suggestions="searchCustomer"
        v-model="currentInputMember"
        placeholder="Input New Customer Name"
        :trigger-on-focus="false"
        v-if="editMode"
        ></el-autocomplete>
        <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Add</button>
      </div>
      <button v-if="!editMode" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="editMode" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Company</th>
          <th v-if="editMode" scope="col">Remove</th>
          <th v-if="!editMode" scope="col">View</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in customers">
          <th scope="row">{{customer.name}}</th>
          <td>{{customer.company}}</td>
          <td v-if="editMode"><a href="#" @click.prevent="removeMember(customer.id)">Remove</a></td>
          <td v-if="!editMode"><router-link :to="{name:'Customer', params: {id: customer.id}}">View</router-link></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'RelatedCustomers',
  data(){
    return {
      editMode: false,
      customers: [],
      currentInputMember: '',
    };
  },
  methods: {
    startEdit(){
      this.editMode = true;
    },
    removeMember(customerId){
      const newCustomers = this.customers.filter(function(item) {
        return item.id !== customerId;
      });
      this.customers = newCustomers;
    },
    async save(){
      const memberName = this.currentInputMember;
      const customer = await this.getCustomerByName(memberName);
      if(customer){
        this.customers.push(customer);
        this.currentInputMember = '';
      }
      this.$emit('updateCustomers', this.customers);
      this.editMode = false;
    },
    async getCustomerByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'name': memberName,
      };
      const customers = await Sales.searchCustomer(this.apiUrl, searchFields, loginInfo);
      const customer = customers[0];
      return customer;
    },
    /* Customer Filter */
    createCustomerFilter(queryString) {
      return (customer) => {
        return (customer.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    /* For auto select */
    async searchCustomer(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const customers = await Sales.loadAllCustomers(this.apiUrl, loginInfo);
      const customerSuggest = [];

      for(let i = 0; i < customers.length; i++){
        const customer = customers[i];
        const customerSelection = {
          'value': customer.name,
          'name': customer.name
        };
        customerSuggest.push(customerSelection);
      }

      const results = queryString ? customerSuggest.filter(this.createCustomerFilter(queryString)) : customers;

      cb(results);
    },
  },
  watch: {
    'currentWork': function(){
      if(this.currentWork.related_customers){
        this.customers = this.currentWork.related_customers;
      }
    }
  },
  computed: mapState({
    currentWork: state => state.work.currentWork,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.customer-members-wrapper{
  .action-button-wrapper{
    margin-bottom: 0;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
  .add-member-wrapper{
    display: flex;
    align-items: center;
    flex: 1;
  }
  .addMemberInput{
    margin-right: 20px;
  }
}
</style>
